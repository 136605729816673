import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('rare-a-lecoute-access-token') || '',
    expirationDate: localStorage.getItem('rare-a-lecoute-expiration-date') || '',
  },
  getters: {
    isAuthenticated: state => !!state.token,
    token: state => {return state.token}
  },
  mutations: {
    'LOGIN_SUCCESS': (state, data) => {
      console.log('LOGIN_SUCCESS mutation')
      state.token = data['token']
      localStorage.setItem('rare-a-lecoute-access-token', data['token'])
      state.expirationDate = data['expirationDate']
      localStorage.setItem('rare-a-lecoute-expiration-date', data['expirationDate'])
    },
  },
  actions: {
  },
  modules: {
  }
})
