<template>
  <div class="container">
    <div class="home-title">
      Bienvenue sur votre espace professionnels de santé - Rare à l'écoute
    </div>
    <div class="home-content">
      <button
        class="home-button"
        @click="login"
      >
        Je me connecte
      </button>
    </div>
  </div>
</template>

<script>
import { proSanteRedictedUrl, proSanteClientId, proSanteAuthEndpoint } from "@/variables/localVariables.js"


export default {
  name: 'HomeView',
  methods: {
    login () {
      window.location.href = `${proSanteAuthEndpoint}?response_type=code&client_id=${proSanteClientId}&redirect_uri=${proSanteRedictedUrl}&scope=openid scope_all&acr_values='eidas1'`
    }
  }
}
</script>
<style>
.home-title {
  text-align: center;
  font-size: 32px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.home-content {
  display: flex;
  justify-content: center;
}

.home-button {
  background-color: #E82632;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;

}
</style>