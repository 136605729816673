import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { notConnectedSpecificToken } from "@/variables/localVariables.js"

const AuthentificationView = () => import('../views/Auth/AuthentificationView.vue')
const VideosPage = () => import('../views/VideosPage.vue')
const VideoPage = () => import('../views/VideoPage.vue')

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  // otherwise check if in query params there is a token 
  else if (to.query.notConnectedToken === notConnectedSpecificToken) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pro-sante/auth/',
    name: 'auth',
    component: AuthentificationView
  },
  {
    path: '/videos',
    name: 'videos',
    component: VideosPage,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/video/:uid',
    name: 'video',
    component: VideoPage,
    beforeEnter: ifAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
